import { Divider, Text, VStack } from "@chakra-ui/react";
import React from "react";

import { getUnixFromISO } from "../utils";
import TooltipContainer from "./TooltipContainer";
import TooltipDataRow from "./TooltipDataRow";
import { xFormatter } from "./xFormatter";

const MyInsightsTooltip: React.FC<{
  active?: boolean;
  payload?: any[]; // recharts: Payload<ValueType, NameType>[]
  label?: string;
  bucketInterval?: string;
  bucketSize?: number;
  dataKey: string;
  compareDataKey?: string;
  dataColor: string;
  compareDataColor: string;
  valueFormatter: (value: number) => string;
  footerText?: string;
}> = ({
  active,
  payload = [],
  bucketInterval,
  bucketSize,
  dataKey,
  compareDataKey,
  dataColor,
  compareDataColor,
  valueFormatter,
  footerText,
}) => {
  if (active && payload.length) {
    const current = payload.find((p) => p.dataKey === dataKey);
    const compare = payload.find((p) => p.dataKey === compareDataKey);
    const { datum, compareDatum } = payload[0].payload;

    const getDisplayValue = (value: any): string =>
      typeof value === "number" ? valueFormatter(value) : "No data";

    const formatWithPrefix = (iso: string): string => {
      const formatted = xFormatter(
        getUnixFromISO(iso),
        bucketInterval,
        bucketSize
      );
      return bucketInterval === "WEEK" ? `Week of ${formatted}` : formatted;
    };

    return (
      <TooltipContainer width="220px">
        <VStack w="100%">
          {current && (
            <>
              <TooltipDataRow
                label={formatWithPrefix(datum.xLabel)}
                value={getDisplayValue(current?.value)}
                bulletColor={dataColor}
              />
              {compareDataKey && (
                <TooltipDataRow
                  label={formatWithPrefix(compareDatum.xLabel)}
                  value={getDisplayValue(compare?.value)}
                  bulletColor={compareDataColor}
                />
              )}
            </>
          )}
          {compare && !current && (
            <TooltipDataRow
              label={formatWithPrefix(compareDatum.xLabel)}
              value={getDisplayValue(compare?.value)}
              bulletColor={compareDataColor}
            />
          )}
          {current && footerText && (
            <>
              <Divider />
              <Text w="100%" textAlign="left">
                {footerText}
              </Text>
            </>
          )}
        </VStack>
      </TooltipContainer>
    );
  }

  return null;
};

export default MyInsightsTooltip;
